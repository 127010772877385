import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { richContentOptions } from "components";

const P = (node, children) => <p className="mt-3 text-gray-500">{children}</p>;

const H6 = (node, children) => (
  <h6 className="text-sm font-medium text-brand-600">{children}</h6>
);

const B = (text) => <strong className="font-medium text-black">{text}</strong>;

export default function TextGrid({
  fields,
  sys,
  includes,
  plain = false,
  addItem = null,
  removeItem = null,
}) {
  const options = {
    ...richContentOptions,
    renderMark: {
      ...richContentOptions.renderMark,
      [MARKS.BOLD]: B,
    },
    renderNode: {
      ...richContentOptions.renderNode,
      [BLOCKS.HEADING_6]: H6,
      [BLOCKS.PARAGRAPH]: P,
    },
  };

  return (
    <div className="py-14 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          {fields.subtitle && (
            <h2 className="text-base text-brand-600 font-semibold tracking-wide uppercase">
              {fields.subtitle}
            </h2>
          )}
          <h3 className="mt-2 text-3xl leading-10 font-sans font-semibold tracking-tight text-gray-900 sm:text-4xl">
            {fields.title}
          </h3>
          <div className="mt-4 max-w-4xl text-xl text-gray-500 lg:mx-auto">
            {fields.intro && documentToReactComponents(fields.intro, options)}
          </div>
        </div>

        <div className="mt-24">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-24">
            {fields.items.map((item) => (
              <div className="flex" key={item.sys.id}>
                <div className="">
                  <dt className="text-2xl leading-relaxed font-semibold font-sans text-gray-900">
                    {item.fields.title}
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    {documentToReactComponents(item.fields.text, options)}
                  </dd>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
